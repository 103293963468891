<template>
  <v-app>
    <v-main>
      <v-card
          elevation="2"
          class="mx-auto my-12"
          max-width="500"
          tile
      >
        <InvoiceForm/>
      </v-card>
      <notifications group="app"/>
    </v-main>
  </v-app>
</template>

<script>
import InvoiceForm from './components/InvoiceForm';

export default {
  name: 'App',

  components: {
    InvoiceForm
  },
};
</script>


<style>
.vue-notification {
  font-size: 16px!important;
  margin: 10px 5px 5px!important;
  border-radius: 4px  !important;
  font-weight: bold!important;
}
</style>
