<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-img
            :src="companyData.logoUrl"
            class="my-2"
            contain
            height="50"
        />
      </v-col>

      <v-col class="mb-4">
        <h3 class="display-2 font-weight-bold mb-3">
          Submit invoice
        </h3>

        <p class="subheading font-weight-regular">
          Submit invoices in the form below.
          We'll confirm and get back to you when processed or paid.
        </p>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col class="mb-4">
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-text-field
              v-model="invoice.name"
              :rules="rules.nameRules"
              label="Name"
              required
          ></v-text-field>

          <v-text-field
              v-model="invoice.email"
              :rules="rules.emailRules"
              label="Email"
              required
          ></v-text-field>

          <v-text-field
              v-model="invoice.description"
              label="Description"
              required
          ></v-text-field>

          <v-currency-field
              prepend-icon="mdi-currency-eur"
              label="Amount"
              :rules="rules.amountRules"
              v-model="invoice.amount"/>

          <v-divider/>
          <v-alert
              border="left"
              elevation="2"
              outlined
              :icon="false"
              colored-border
              color="grey lighten-2"
              class="text-left"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  Include the following on your invoice:
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{companyData.name}}
                </v-list-item-title>
                <v-list-item-title>
                  {{companyData.address}}
                </v-list-item-title>
                <v-list-item-title>
                  {{companyData.address2}}
                </v-list-item-title>
                <v-list-item-title>
                  {{companyData.country}}
                </v-list-item-title>
                <v-list-item-title>
                  VAT: {{companyData.vat}}
                </v-list-item-title>

                <v-divider class="ma-3"/>

                  <p class="font-italic font-weight-light text-justify">
                    In case of an invoice for a royalty statement please include the “account” and “label” as mentioned on your royalty summary PDF, otherwise we can’t process the invoice.
                  </p>
              </v-list-item-content>
            </v-list-item>
          </v-alert>
          <v-file-input
              show-size
              truncate-length="15"
              v-model="invoice.file"
              :rules="rules.invoiceRules"
          ></v-file-input>

          <v-text-field
              v-if="companyData.domain === 'wdwkmusic.com'"
              v-model="invoice.record_label_hint"
              label="Record label this invoice is applicable to"
              required
          ></v-text-field>
          <v-switch
              v-model="invoice.isNL"
              label="NL invoice"
          ></v-switch>
          <vue-recaptcha
              ref="recaptcha"
              sitekey="6LeRck4kAAAAAIjQmJfzdp4_3NETAV-g-nyFAlID"
              @verify="verifyMethod"
              size="invisible"
          ></vue-recaptcha>
          <v-btn
              :disabled="!valid"
              color="success"
              class="mr-4"
              @click="recaptchaValidation"
          >
            Submit
          </v-btn>

        </v-form>
      </v-col>
    </v-row>

    <v-overlay :value="overlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import axios from 'axios';
import { findLast } from "lodash";
import companyConfigs from "@/configs/companyConfigs";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: 'InvoiceForm',
  components: {VueRecaptcha},
  data: () => ({
    valid: true,
    overlay: false,
    notify: {
      show: true,
      text: ''
    },
    showNotify: false,
    rules: {
      nameRules: [
        v => !!v || 'Name is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      amountRules: [
        v => !!v || 'Amount is required',
      ],
      invoiceRules: [
        v => !!v || 'Invoice file is required',
        v => !v || v.size < 10000000 || 'Invoice file size should be less than 10 MB!',
      ],
    },
    invoice: {
      name: '',
      email: '',
      description: '',
      amount: null,
      file: null,
      isNL: false,
      record_label_hint: '',
    }
  }),
  computed: {
    companyData() {
      const {href} = window.location;
      // const href = 'knightvisionrec.com';
      // const href = 'wdwkmusic.com';
      return findLast(companyConfigs, (data) => href.includes(data.domain)) || companyConfigs[0];
    },
    uid() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('uid');
    }
  },
  methods: {
    verifyMethod(token) {
      this.resetCaptcha();
      this.submitForm(token);
    },
    recaptchaValidation() {
      this.$refs.recaptcha.execute()
    },
    submitForm(token) {
      if (!this.$refs.form.validate()) {
        return false;
      }
      this.overlay = true;
      const formData = new FormData();
      const {invoice} = this;
      // const urlBB = 'https://knightvision-staging.bettywebblocks.com/wdwk/invoice';
      const urlBB = 'https://knightvision.bettywebblocks.com/wdwk/invoice';
      formData.append('url', urlBB);
      formData.append('name', invoice.name);
      formData.append('uid', this.uid);
      formData.append('email', invoice.email);
      formData.append('description', invoice.description);
      formData.append('record_label_hint', invoice.record_label_hint);
      formData.append('amount', invoice.amount);
      formData.append('isNL', invoice.isNL);
      formData.append('file', invoice.file);
      formData.append('g-recaptcha-response', token);
      formData.append('domain', this.companyData.domain);
      axios.post('https://services.wdwkmusic.com/api/form/handler', formData,
          // axios.post('https://services-staging.wdwkmusic.com/api/form/handler', formData,
          // axios.post('//wdw.loc/api/form/handler', formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then((request) => {
        const {data} = request;
        if (data.error) {
          this.errorNotification();
          return;
        }
        this.successNotification();
        this.$refs.form.reset()
      }).catch(() => {
        this.errorNotification();
      }).finally(() => {
        this.overlay = false;
      })

    },
    validate() {
      this.$refs.form.validate()
    },
    reset() {
      this.$refs.form.reset()
    },
    resetCaptcha() {
      this.$refs.recaptcha.reset();
    },
    errorNotification() {
      this.$notify({
        group: 'app',
        type: 'error',
        text: 'An error has occurred, please try reloading the page and filling ' +
            'out the form again or contact our support team with your question.',
        duration: 10000
      })
    },
    successNotification() {
      this.$notify({
        group: 'app',
        type: 'success',
        text: 'Invoice was sent successfully',
        duration: 10000
      })
    },
  }
}
</script>
