export default [
    {
        domain:'knightvisionrec.com',
        name: 'Knightvision BV',
        logoUrl:'//assets.bettyblocks.com/6cc35e79589e4936a35ed1df82cee2be_f5b72b401b43449ebc573042efd2498f/2/' +
            'KnightVision_LOGO_form__1_.png',
        address: 'Nieuwendijk 44-2',
        address2: '1012MN Amsterdam',
        country: 'The Netherlands',
        vat: '857555248B01',
    },

    {
        domain:'wdwkmusic.com',
        name: 'WideAwake BV',
        logoUrl:'//assets.bettyblocks.com/534c6056977d463c97a7322b29d1f881_assets/files/' +
            'logo_for_Statement?_=1f385e236c484d8f4be3a0cd043aabfc',
        address: 'Nieuwendijk 44-2',
        address2: '1012MN Amsterdam',
        country: 'The Netherlands',
        vat: 'NL857555236B01',
    }
]

